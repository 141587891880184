import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ImpersonateDialogComponent} from './impersonate-dialog/impersonate-dialog.component';
import {SwitchOrgDialogComponent} from './switch-org-dialog/switch-org-dialog.component';
import {SwitchOrgService} from './switch-org-dialog/switch-org.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [DialogService],
})
export class NavbarComponent implements OnInit, OnDestroy {
  title = 'vCap - title error';
  menuItems: any;
  userInfo: any;
  allowedFeatures: string[] = [];
  organization_users: any[] = [];

  oldToken: string | null = null;
  oldUsername: string | null = null;
  userInfoSubscription: any;
  getOrgUsersSubscription: any;
  getFeatureSubscription: any;
  impersonateDialog: any;
  switchOrgDialog: any;
  routerSubscription: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    public dialogService: DialogService,
    private switchOrgService: SwitchOrgService,
  ) {
    this.routerSubscription = router.events.subscribe(() => {
      this.impersonateDialog?.close();
      this.switchOrgDialog?.close();
      let route = this.router.routerState.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      route.data.subscribe((str) => {
        if (str['title'])
          //Check if filled in (for subpages) If page title doesn't update, this is why. Check if title data is passed in router.
          this.title = str['title'];
      });
    });
  }

  ngOnDestroy(): void {
    this.userInfoSubscription?.unsubscribe();
    this.getOrgUsersSubscription?.unsubscribe();
    this.getFeatureSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.userInfoSubscription = this.authService
      .getUserInfo()
      .subscribe((response: any) => {
        this.userInfo = response;

        if (response.is_superadmin) this.userInfo.allow_impersonate = true;

        this.getFeatureSubscription = this.authService
          .getAllowedFeatures()
          .subscribe((response: any) => {
            response.forEach((item: any) => {
              this.allowedFeatures.push(item.feature_name);
            });
            this.menuItems = [
              {
                routerLink: 'quickview',
                label: 'Quickview',
                icon: 'pi pi-forward',
                visible: this.allowedFeatures.includes('quickview'),
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'liveview',
                label: 'Liveview',
                icon: 'pi pi-play',
                visible: this.allowedFeatures.includes('liveview'),
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'power-dashboard',
                label: 'Power Dashboard',
                visible: this.allowedFeatures.includes('power_dashboard'),
                icon: 'pi pi-bolt',
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'thermal-dashboard',
                label: 'Thermal Dashboard',
                visible: this.allowedFeatures.includes('thermal_dashboard'),
                icon: 'pi pi-eye',
                routerLinkActiveOptions: {exact: true},
              },
              {
                label: 'Events',
                icon: 'pi pi-cloud-upload',
                routerLinkActiveOptions: {exact: false},
                visible: this.allowedFeatures.includes('events'),
                items: [
                  {
                    routerLink: 'events/overview',
                    label: 'Overview',
                    routerLinkActiveOptions: {exact: true},
                  },
                  {
                    routerLink: 'events/motion',
                    label: 'Motion',
                    routerLinkActiveOptions: {exact: true},
                  },
                  {
                    routerLink: 'events/timer',
                    label: 'Timer',
                    routerLinkActiveOptions: {exact: true},
                  },
                  {
                    routerLink: 'events/ptz-round',
                    label: 'PTZ Round',
                    routerLinkActiveOptions: {exact: true},
                  },
                  // {
                  //   routerLink: 'events/anpr',
                  //   label: 'ANPR',
                  //   routerLinkActiveOptions: {exact: true},
                  // },
                  // {
                  //   routerLink: 'events/stitch',
                  //   label: 'Stitch',
                  //   routerLinkActiveOptions: {exact: true},
                  // },
                ],
              },
              {
                label: 'Downloads',
                icon: 'pi pi-download',
                visible: this.allowedFeatures.includes('downloads'),
                items: [
                  {routerLink: 'downloads/camera-videos', label: 'Camera Videos'},
                  {routerLink: 'downloads/timelapses', label: 'Timelapses'},
                  {
                    routerLink: 'downloads/image-collections',
                    label: 'Image Collections',
                  },
                  {
                    routerLink: 'downloads/scheduled-tasks',
                    label: 'Scheduled Tasks',
                  },
                ],
              },
              {
                label: 'Cases',
                icon: 'pi pi-file',
                visible: this.allowedFeatures.includes('analysis'),
                routerLink: 'cases',
              },
              {
                label: 'Configurations',
                icon: 'pi pi-box',
                visible: this.allowedFeatures.includes('configurations'),
                items: [
                  {routerLink: 'configurations/overview', label: 'Overview'},
                  {routerLink: 'configurations/configurations', label: 'Configurations'},
                  {routerLink: 'configurations/triggers', label: 'Triggers'},
                  {
                    routerLink: 'configurations/multi-stitch',
                    label: 'Multi Stitch',
                  },
                  // {
                  //   routerLink: 'configurations/trigger-groups',
                  //   label: 'Trigger Groups',
                  // },
                ],
              },
              {
                label: 'Accessories',
                icon: 'pi pi-link',
                visible: this.allowedFeatures.includes('accessories'),
                items: [
                  {routerLink: 'accessories/lamps', label: 'Lamps'},
                  {routerLink: 'accessories/power-usage', label: 'Power Usage'},
                ],
              },
              {
                label: 'Organization',
                icon: 'pi pi-sitemap',
                visible: this.allowedFeatures.includes('organization'),
                items: [
                  {routerLink: 'organization/users', label: 'Users'},
                  {routerLink: 'organization/info', label: 'Info', visible: this.userInfo.type.includes('admin')},
                ]
              },
              {
                label: 'Support',
                icon: 'pi pi-question-circle',
                items: [
                  {label: 'Contact support', command: () => location.href = 'mailto:support@viewandintegrate.be'},
                  {routerLink: 'help', label: 'Documentation'},
                ],
              },
            ];
          });
      });

    this.getOrgUsersSubscription = this.switchOrgService
      .getOrganizationUsers()
      .subscribe((data: any) => {
        this.organization_users = data;
      });

    this.oldToken = localStorage.getItem('old_token');
    this.oldUsername = localStorage.getItem('old_username');
  }

  logOut() {
    this.authService.logout(true);
  }

  show_impersonate_switch() {
    this.impersonateDialog = this.dialogService.open(
      ImpersonateDialogComponent,
      {
        header: $localize`User Impersonation`,
        width: '50%'
      }
    );
  }

  show_org_switch() {
    this.switchOrgDialog = this.dialogService.open(SwitchOrgDialogComponent, {
      header: $localize`Switch organization`,
      width: '50%',
    });
  }

  endImpersonate() {
    let old_token = localStorage.getItem('old_token');
    let old_username = localStorage.getItem('old_username');

    if (old_token && old_username) {
      localStorage.clear();
      localStorage.setItem('token', old_token);
      localStorage.setItem('username', old_username);

      this.router.navigate(['/']);
      window.location.reload();
    }
  }
}
